import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Straaleterapi from "../../components/Straaleterapi.js"
import Knap from "../../components/Knap.js"


const StraaleterapiPage = () => (
    <LayoutComponent>
        <h1>Strålterapi</h1>
        <p>Hos Easymed har vi en liten, men noga utvald grupp av leverantörer, som kan leverera tillbehör till dosimetri, dosimeter, strålanalys, kvalitetskontroll (QA) och andra strålterapeutiska instrument.</p>
        <h1>Våra leverantörer</h1>
        <Straaleterapi />
        <Knap />
    </LayoutComponent>
)
export default StraaleterapiPage